
import './App.css'

import WebFont from 'webfontloader'

import { useMemo, useRef, useEffect } from 'react'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { size } from 'lodash'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import Grow from '@mui/material/Grow'
import Box from '@mui/material/Box'

import { useRecoilValue, useSetRecoilState } from 'recoil'

import { pageStateAtom } from '@state/page'
import { companyStateAtom } from '@state/company'
import { initDataStateAtom } from '@state/initData'
import { darkModeStateAtom } from '@state/darkMode'
import { skinStateAtom } from '@state/skin'

import Currencies from '@components/Currencies'

import Desktop from '@components/Desktop'
import Mobile from '@components/Mobile'
import Bot from '@components/Bot'

import Header from '@components/Header'
import PopupCallBack from '@components/PopupCallBack'
import Start from '@components/Start'
import Brands from '@components/Brands'
import Models from '@components/Models'
import Products from '@components/Products'
import Product from '@components/Product'
import Menu from '@components/Menu'
import CallBackButton from '@components/Buttons/CallBack'

import Auth from '@components/Auth'


import GlobalProgressBackdrop from '@components/GlobalProgressBackdrop'

import useBotDetect from '@hooks/useBotDetect'
import useMobileDetect from '@hooks/useMobileDetect'
import usePlatformDetect from '@hooks/usePlatformDetect'

import useThemeMode from '@hooks/useThemeMode'

import parseDynamicComponent from '@utils/parseDynamicComponent'

import grey from '@mui/material/colors/grey'

import { useFullscreen } from 'ahooks';

const borderRadius = 5

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 980,
      lg: 1240,
      xl: 1626,
    },
  },
  palette: {
    primary: grey,
    secondary: grey,
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingTop: 24,
          userSelect: 'none',
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: borderRadius,
          borderColor: 'none',
          border: 0, 
          '& .MuiOutlinedInput-root': {
            borderRadius: borderRadius,
            height: 50,
            border: '0px solid #909090',
            padding: 5,

            ':hover': {
              border: '0px solid #fd0000 !important',
              //boxShadow: '-1px 1px 4px 4px rgba(219, 219, 219, 0.25);',
              borderRadius: borderRadius,
              borderColor: 'none',
            },
            ':focus-within': { border: '0px solid #fd0000 !important' }
          },
          '& .MuiOutlinedInput-root.Mui-disabled': {
            ':hover': {
              border: '0px solid #909090 !important',
              boxShadow: 'none'
            }
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          },
          '& .MuiOutlinedInput-input': {
            padding: 10,
          }
        },
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            backgroundColor: 'unset',
          },

        }
      }
    },
    MuiPaper:{
      styleOverrides: {
        root: {
          boxShadow: "unset",
          transition: 'unset',
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          //backgroundColor: "rgb(52, 52, 52)",

        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiIconButton-root': {
            height: 'unset',
          },
        },
        paper: {
          //backgroundColor: 'rgba(255, 255, 255, 1)',
          borderRadius: borderRadius,
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', // Добавление тени к выпадающему списку
        },
        option: {
          '&[aria-selected="true"]': {
            backgroundColor: '#e3abed',
          },
          '&': {
            //backgroundColor: '#fff',
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          background: 'unset',
          border: 0,
          ':hover': {
            background: 'unset',

          },
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          background: 'unset',

          height: 50,
          border: 0,
          ':hover': {

          },
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root:{
          userSelect: 'none',
        }
      }
    },
    MuiInputBase: {
        styleOverrides: {
          root: {
            color: '#000',

            background: '#F3F3F3',
            //background: 'rgba(255, 255, 255, 0.1)',

            borderWidth: '26px',
            borderRadius: '25px',
            borderColor: 'none',
            border: 0,

            ':hover': {
              borderRadius: '25px',
              borderColor: 'none',
              border: 0,
            },

            '& .MuiOutlinedInput-root': {
              borderRadius: '7px',
              height: 50,
              border: '1px solid #909090',
              
              ':hover': {
                border: '0px solid #fd0000 !important',
                boxShadow: '-1px 1px 4px 4px #FFEAEA'
              },
              ':focus-within': { border: '0px solid #fd0000 !important' }
            },
            '& .MuiOutlinedInput-root.Mui-disabled': {
              ':hover': {
                border: '0px solid #909090 !important',
                boxShadow: 'none',
              }
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none'
            }
          },
        }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 15,
          padding: 20,
          border: 0,
          ':hover': {
            border: 0,
            background: 'unset',
            backgroundColor: 'unset',
          },
          color: '#fff'
        }
      }
    }
},
transitions: {
  duration: {
      enteringScreen: 1000, // 1000,
      leavingScreen: 100, // 350,
  }
}
})

const App = () => {

  const bot = useBotDetect()
  const mobile = useMobileDetect()
  const platform = usePlatformDetect()
  
  const page = useRecoilValue<any>(pageStateAtom)
  
  const initData = useRecoilValue<boolean>(initDataStateAtom)
  
  const setSkin = useSetRecoilState<string>(skinStateAtom)

  const company = useRecoilValue<any>(companyStateAtom)
  const darkMode = useRecoilValue<any>(darkModeStateAtom)
  
  const themeMode = useThemeMode()
  
  const design:any = useMemo(() => parseDynamicComponent(company?.design),[company?.design])

  const ref = useRef(null);

  const [isFullscreen, { enterFullscreen, exitFullscreen, toggleFullscreen }] = useFullscreen(ref);

  useEffect(() => {

    const hasSkinOnlyCatalog = window.location.href.includes('?skin=onlyCatalog');

    if(hasSkinOnlyCatalog) setSkin('catalogFixFilters');
   }, [])

  useEffect(() => {

    WebFont.load({
      google: {
        families: ['Inter', 'Chilanka']
      }
    })
   }, [])

   useEffect(() => {

    console.log('window?.Telegram')
    console.log(window?.Telegram?.WebApp?.initData)

    window?.Telegram?.WebApp?.expand()
    
    window?.Telegram?.WebApp?.onEvent('viewportChanged', () => {

      if (!window?.Telegram?.WebApp?.isExpanded){

        window?.Telegram?.WebApp?.expand()
      }
    })
/* 
   if( window?.Telegram?.WebApp) window?.Telegram?.WebApp?.isClosingConfirmationEnabled = true;
   if( window?.Telegram?.WebApp) window?.Telegram?.WebApp?.isClosingConfirmationEnabled = true;
*/
/* 
    window?.Telegram?.WebApp?.requestContact()

    window?.Telegram?.WebApp?.onEvent(`contactRequested`, (data:any)=>{
      if(data?.status === `send`){
        return alert(`контакты отправленны прям в бот`)
      }
    })
*/
  }, [])

  /* start fixed telegram */

  useEffect(() => {

    const interval = setInterval(() => {
      
      if(window?.Telegram?.WebApp?.initData){

        if(window.pageYOffset !== 1){

          const elem = document.body

          if(elem) elem.style.height = `calc(100vh + 1px)`;
      
          window.scrollTo(0, 1)
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [])

  /* end fixed telegram */

  const mode = themeMode

  const light = {
    text: design?.data?.[0]?.color_light ? design?.data?.[0]?.color_light : 'rgb(188, 192, 195)',
    background: design?.data?.[0]?.background_color_light ? design?.data?.[0]?.background_color_light : 'rgb(255, 255, 255)',
    buttonBackgroundColor: design?.data?.[0]?.button_background_color_light ? design?.data?.[0]?.button_background_color_light : 'rgb(32, 33, 36)',
    buttonColor: design?.data?.[0]?.button_color_light ? design?.data?.[0]?.button_color_light : 'rgb(32, 33, 36)',
  }

  const dark = {
    text: design?.data?.[0]?.color_dark ? design?.data?.[0]?.color_dark : 'rgb(188, 192, 195)',
    background: design?.data?.[0]?.background_color_dark ? design?.data?.[0]?.background_color_dark : 'rgb(32, 33, 36)',
    buttonBackgroundColor: design?.data?.[0]?.button_background_color_dark ? design?.data?.[0]?.button_background_color_dark : 'rgb(32, 33, 36)',
    buttonColor: design?.data?.[0]?.button_color_dark ? design?.data?.[0]?.button_color_dark : 'rgb(32, 33, 36)',
  }

  return  <>
          <GoogleReCaptchaProvider 
          reCaptchaKey={company?.recaptcha?.recaptcha_v3?.public}
          container={{
            parameters: {
              badge: 'bottomright', // optional, default undefined
              theme: 'dark', // optional, default undefined
            }
          }}>
            <ThemeProvider
            theme={{
              ...theme, 
              components: { 
                ...theme.components, 

                MuiInputBase: {
                  styleOverrides: {
                    root: {
                      color: mode === 'dark' ? dark.text : light.text,
                      background: mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(243, 243, 243, 1)',
                    }
                  }
                },
                MuiButtonGroup: {
                  styleOverrides: {
                    root: {
                      color: mode !== 'dark' ? dark.buttonColor : light.buttonColor,
                    }
                  }
                },
                MuiBackdrop: {
                  styleOverrides: {
                    root: {
                      backgroundColor: mode === 'dark' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.5)' ,
                    }
                  }
                },
              },
              palette: {
                ...theme.palette,
                               
                divider: mode === 'dark' ? dark.background : light.background,

                background: {
                  default: mode === 'dark' ? dark.background : light.background,
                  paper: mode === 'dark' ? dark.background : light.background,
                },
                text: {
                  primary: mode === 'dark' ? dark.text : light.text,
                  secondary: mode === 'dark' ? dark.text : light.text,
                },
                primary: {
                  main: mode === 'dark' ? dark.buttonColor : light.buttonColor,
                  company: mode === 'dark' ? dark.buttonBackgroundColor : light.buttonBackgroundColor,
                },

                action: {
                  ...theme?.palette?.action, 
                },
              }
            }}>
              
              <CssBaseline />

              <Box ref={ref}>
              

              {initData && platform === `bot` && bot && mobile && page !== `start` && size(company) > 0 &&
                
                <Box>

                  <Bot/>

                </Box>

              }
              
              {(initData && platform === `mobile` && !bot && mobile && page !== `start` && size(company) > 0) &&

                <Box>

                  <Mobile/>

                </Box>
              }


              {(initData && platform === `desktop` && !bot && !mobile && page !== `start` && size(company) > 0) &&

                <Box>

                  <Desktop/>

                </Box>

              }

              <Currencies/>

              <Product/>

              <CallBackButton/>

              <PopupCallBack/>

              <Auth/>

              <GlobalProgressBackdrop/>

              <Grow in={(!initData) || false } unmountOnExit>
                <Box>
                  <Start/>
                </Box>
              </Grow>

              </Box>

            </ThemeProvider>
            </GoogleReCaptchaProvider>
          </>
  
}

export default App
