
import { FC, useState } from 'react'

import { useRecoilState, useRecoilValue } from 'recoil'

import { authPageStateAtom } from '@state/authPage'
import { authOpenStateAtom } from '@state/authOpen'

import DesktopOrMobileOrBot from '@components/DesktopOrMobileOrBot'

import LoginOrRegister from './LoginOrRegister'
import PhoneConfirmed from './PhoneConfirmed'

const Auth: FC<any> = () => {

  const [ open, setOpen ] = useRecoilState<boolean>(authOpenStateAtom)

  const authPage = useRecoilValue(authPageStateAtom)

  const onClose = () => setOpen(false);

  return  <DesktopOrMobileOrBot open={open || false} onClose={onClose}> 
              
            {authPage === 'loginOrRegister' && <LoginOrRegister/>}

            {authPage === 'phoneConfirmed' && <PhoneConfirmed/>}
                        
          </DesktopOrMobileOrBot>
}

export default Auth
