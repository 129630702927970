
import { FC, useRef, useEffect, useState } from 'react'

import axios from 'axios'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { callBackStateAtom } from '@state/callBack'
import { callBackAttachDataStateAtom } from '@state/callBackAttachData'

import { jwtStateAtom } from '@state/jwt'
import { authPhoneStateAtom } from '@state/authPhone'
import { userDataStateAtom } from '@state/userData'
import { authPageStateAtom } from '@state/authPage'

import { authOpenStateAtom } from '@state/authOpen'


import MuiPhoneNumber from 'material-ui-phone-number'

import CircularProgress from '@mui/material/CircularProgress'

import { useForm, Controller } from 'react-hook-form'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'

import Divider from '@mui/material/Divider';

import { LoadingButton } from '@mui/lab'
import Button from '@mui/material/Button'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import SendIcon from '@mui/icons-material/Send'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"

import Typography from '@mui/material/Typography'

import DoneIcon from '@mui/icons-material/Done'

import useMobileDetect from '@hooks/useMobileDetect'

import DesktopOrMobileOrBot from '@components/DesktopOrMobileOrBot'

import api from '@services/api'
import { MD5 } from 'crypto-js'
import { randomInt } from 'crypto'

const PhoneConfirmed: FC<any> = () => {

  const mobile = useMobileDetect()

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ send, setSend ] = useState<boolean>(false)

  const [ callNumber, setCallNumber ] = useState<string|null>(null)

  const [ open, setOpen ] = useRecoilState<boolean>(authOpenStateAtom)

  const [ failAuth, setFailAuth ] = useState<boolean>(false)

  const authPhone = useRecoilValue(authPhoneStateAtom)

  const [ jwt, setJwt ] = useRecoilState<boolean>(jwtStateAtom)
  const [ userData, setUserData ] = useRecoilState<boolean>(userDataStateAtom)

  const setAuthPage = useSetRecoilState(authPageStateAtom)


  const intervalIDRef = useRef<any>(null);

  const getCallNumber = () => {

    api({
      method: 'POST',
      collection: 'phone-confirming/wait_call_init', 
      data: { 
        phone: authPhone, 
      }
    }).then((data) => {

      console.log(data)

      setCallNumber(data?.data?.phone)

    }).catch((error:any) => {

      console.log(error)
    })
  }



  const checkingPhoneConfirmed = () => {

    api({
      method: 'POST',
      collection: 'phone-confirming/wait_call_checking', 
      data: { 
        phone: authPhone, 
      }
    }).then((data) => {

      console.log(data)
      console.log(data?.status)

      if(data?.status === 'ok'){

        setJwt(data.data.jwt)
        setUserData(data.data.user)

        return setOpen(false)

      }



      /* if(data?.data?.confirmed) {

        api({
          method: 'POST',
          collection: 'auth/local', 
          data: { 
            identifier: authPhone, 
            password: data?.data?.password, 
          }
        }).then((data) => {

          setSend(true)
          setLoading(false)

          if(data&&data.jwt){

            setJwt(data.jwt)
            setUserData(data.user)

            return setOpen(false)
          }

          return alert('Неверные данные')

        }).catch((error:any) => {

          setLoading(false)

          if(error?.response?.data?.error?.message==='Invalid identifier or password'){

            setFailAuth(true)

            setTimeout(()=>{
              setFailAuth(false)
            }, 4500)
          }
        })
      } */

    }).catch((error:any) => {

      console.log(error)
    })
  }

  useEffect(() => {

    intervalIDRef.current = setInterval(() => checkingPhoneConfirmed(), 2500);

    return () => clearInterval(intervalIDRef.current);
  }, [])

  useEffect(() => {

    if(!authPhone) return;

    getCallNumber()
  }, [])

  const onSubmit = async (data:any) => {

    window?.navigator?.vibrate && window?.navigator?.vibrate(10);
    setLoading(true)

    /* console.log("getValues('phone')")
    console.log(getValues('phone'))

    const formattedPhone = formatPhoneNumber(getValues('phone'));
    console.log('formattedPhone')
    console.log(formattedPhone)
    console.log(MD5(getValues('phone')).toString())

    api({
      method: 'POST',
      collection: 'auth/local', 
      data: { 
        identifier: formattedPhone, 
        password: MD5(getValues('phone')).toString(), 
        // password: getValues('password'), 
      }
    }).then((data) => {

      setSend(true)
      setLoading(false)

      if(data&&data.jwt){

        setJwt(data.jwt)
        setUserData(data.user)

        return setOpen(false)
      }

      return alert('Неверные данные')

    }).catch((error:any) => {

      setLoading(false)

      if(error?.response?.data?.error?.message==='Invalid identifier or password'){

        setFailAuth(true)

        setTimeout(()=>{
          setFailAuth(false)
        }, 4500)
      }
    }) */
  }

  const onClose = () => setOpen(false);

    return <>
            
      <Typography sx={{...(!mobile && { m: 2.5, mt: 0, }), textAlign: "center" ,  typography: {  xs: 'h6', sm: 'h6', md: 'h6', lg: 'h6' }}}>

        Подтверждения
        
      </Typography>

      { !callNumber ?

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            color: 'text.primary'
          }}
        >

          <CircularProgress sx={{
            color: 'text.primary'
          }}/>

          <Typography variant="subtitle2" gutterBottom  align="center" sx={{ mt: 2.5 , ml: 5, mr: 5}}>
            
            <>Ждем проверочный номер телефона</>
            
          </Typography>
          
        </Box>
        :
        <>

          <Typography sx={{...(!mobile && { m: 2.5, mt: 0, }), textAlign: "center" ,  typography: {  xs: 'body1', sm: 'body1', md: 'body1', lg: 'body1' }}}>

            Для подтверждения вашего номера, пожалуйста, позвоните на номер {callNumber}. Когда робот ответит, сбросьте звонок — номер будет подтверждён автоматически.
          
          </Typography>

          <Typography sx={{...(!mobile && { m: 0.5, }), textAlign: "center" ,  typography: {  xs: 'body2', sm: 'body2', md: 'body2', lg: 'body2' }}}>

            Робот ждет вашего звонка
          
          </Typography>

          <Typography sx={{...(!mobile && { m: 0.5, mb: 1 }), textAlign: "center" ,  typography: {  xs: 'body2', sm: 'body2', md: 'body2', lg: 'body2' }}}>

            позвоните с +{authPhone} на {callNumber}
          
          </Typography>

          <Box
          component="form"
          sx={{
            width: '100%',
            padding: 0,
            '& > :not(style)': { mt: 1, mb: 1, width: '100%' },
          }}
          noValidate
          autoComplete="off"
          >

          <LoadingButton
          sx={{
            bgcolor: 'primary.company',
            "&:hover": {
              bgcolor: 'primary.company',
              opacity: 0.7,
            },
            "&:active": {
              bgcolor: 'primary.company',
              opacity: 0.7,
            },
            '& .MuiButton-endIcon': {
              marginTop: -0.5,
            }
          }}
          loading={loading} variant="outlined" onClick={()=>{

            window.open(`tel:${callNumber}`, '_blank')

          }}>Позвонить на {callNumber}</LoadingButton>

          <Grid sx={{ textAlign: 'center', mt: 2.5 }}>

            <Typography  variant="body2" color="textSecondary" onClick={() => setAuthPage('loginOrRegister')}>Изменить номер</Typography>

          </Grid>

          </Box>
        </>
      }

    </>
}

export default PhoneConfirmed
